import React from 'react'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class Operations extends React.Component {
	render() {
		const siteTitle = 'Operations Review, Documentation & Improvement'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<Container className="py-5">
					<Row>
						<Col id="operations" className="my-4">
							<p className="mt-1 mb-0">
								Processes should be efficient, time is money.
							</p>
							<p className="mt-1 mb-0">
								Processes should be documented, why re-invent the wheel each
								time.
							</p>
							<p className="mt-1 mb-4">
								Processes should be followed, efficiency is key
							</p>
							<h4 className="mt-2 mb-3">
								What Can Process Documentation and Improvement Do For Your
								Business?
							</h4>
							<p>
								At its heart, process improvement strives to meet and accomplish
								business goals with minimal waste. Process improvement runs on
								the principle of comparins; so whatever gets measured, gets
								done. It is used widely to bring order to deficiencies in a
								process or a system to harmonize them with the enterprise goals.
							</p>
							<p className="mt-1 mb-1">
								<b>Two key fundamentals every organization should live by:</b>
							</p>
							<h6>Processes are only good if they are followed.</h6>
							<h6>Processes can only be followed, if they are documented.</h6>
						</Col>
					</Row>
				</Container>
			</Layout>
		)
	}
}

export default Operations

export const OperationsPageQuery = graphql`
	query OperationsPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
